import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Heading } from '@legalshield/adonis-ux-framework';
import { Questions } from '@legalshield/frontend-commons';
import { Answer, AnswerTask } from '@legalshield/frontend-commons/dist/sdk/answers';
import { S3UploadResponse } from '@legalshield/frontend-commons/src/sdk/imagestore';
import { FileWithPreview, QuestionnaireRenderer } from '@legalshield/products-questionnaire-lib';
import { QuestionnaireRenderer as QuestionnaireRenderer_NEW } from '@legalshield/products-questionnaire-lib_NEW';

import config from '../../config';
import { flags } from '../../constants/featureFlags';
import { findAttachments, uploadAttachments } from '../../services/answers/attachments';
import createAnswers from '../../services/answers/createAnswers';
import replaceAnswer from '../../services/answers/replaceAnswer';
import submitAnswer from '../../services/answers/submitAnswer';
import getQuestions from '../../services/questions/getQuestions';
import { setLoading, showMessage } from '../App/app.actionCreators';
import AppContext from '../App/AppContext';
import ContentLayout from '../shared/ContentLayout/ContentLayout';
import { Attachment } from './QuestionnaireResponse.interfaces';
const SL2334 = flags[config.getEnvFromHostname()].SL2334;

const QUESTIONNAIRE_ID = 'referral-network-application-v1.0';
const NAVIGATION_WARNING = "Are you sure you'd like to leave? You will lose the information you've entered so far.";

const ReferralNetworkApplication: FC = () => {
  const { appDispatch } = useContext(AppContext);
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [questions, setQuestions] = useState<Questions.Question | null>(null);
  const history = useHistory();

  useEffect(() => {
    appDispatch(setLoading(true));
    getQuestions(QUESTIONNAIRE_ID)
      .then((resp) => {
        setQuestions(resp);
        appDispatch(setLoading(false));
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        appDispatch(setLoading(false));
        appDispatch(showMessage(e.title, e.message, false, true));
      });
  }, []);

  useEffect(() => {
    const unblock = history.block(() => {
      if (blockNavigation) {
        return NAVIGATION_WARNING;
      }
    });

    return () => {
      unblock();
    };
  }, [blockNavigation, history]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (tasks: Record<string, any>) => {
    setBlockNavigation(false);
    try {
      appDispatch(setLoading(true));

      const answer: Answer = await createAnswers({
        questionnaireName: QUESTIONNAIRE_ID,
        taskCount: 17,
        tasks: tasks,
      });
      const version = answer.version as number;
      const id = answer.id as string;

      const attachments: File[] = findAttachments(tasks).map((attachment: FileWithPreview) => {
        return new File([attachment], attachment.filename);
      });

      const uploadResponses: S3UploadResponse[] = await uploadAttachments(id, attachments);

      tasks.task14.attachments.forEach((attachment: Attachment) => {
        const uploadResponse = uploadResponses.find(
          (response: S3UploadResponse) => response.file_name_original === attachment.filename,
        );
        if (uploadResponse) {
          attachment.url = uploadResponse.file_url;
          attachment.preview = uploadResponse.file_url;
        }
      });

      const replacedAnswer: Answer = await replaceAnswer(version, id, {
        questionnaireName: QUESTIONNAIRE_ID,
        taskCount: 17,
        tasks: tasks as unknown as Record<string, AnswerTask>,
      });

      await submitAnswer(replacedAnswer.version, id);
      history.push('/success');
      appDispatch(setLoading(false));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      appDispatch(setLoading(false));
      appDispatch(showMessage(e.title, e.message, false, true));
    }
  };

  return (
    <>
      {!questions ? (
        <></>
      ) : (
        <ContentLayout width="75%">
          <Container classNames={['mb-5']}>
            <Heading as="T28" text="Referral Network Application" />
          </Container>
          {SL2334 ? (
            <QuestionnaireRenderer_NEW schema={questions} onSubmit={handleSubmit} />
          ) : (
            <QuestionnaireRenderer schema={questions} onSubmit={handleSubmit} />
          )}
        </ContentLayout>
      )}
    </>
  );
};

export default ReferralNetworkApplication;
