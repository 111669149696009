import { z } from 'zod';

import config from '../config';

const flagSchema = z.object({
  SL2334: z.boolean(), // Task 5 bug fix - Invalid date allowed
});

type FeatureFlags = z.infer<typeof flagSchema>;
export const flags: Record<string, FeatureFlags> = {
  [config.LSEnvironment.Dev]: {
    SL2334: true,
  },
  [config.LSEnvironment.Uat]: {
    SL2334: true,
  },
  [config.LSEnvironment.ProductionTest]: {
    SL2334: false,
  },
  [config.LSEnvironment.Production]: {
    SL2334: true,
  },
};
